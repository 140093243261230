mcWeb.eniro = new function() {
  var self = this;
  self.service = new WebApiService("Eniro");
  this.people = ko.observableArray();

  this.gridViewModel = new ko.simpleGrid.viewModel({
    data: this.people,
    columns: [
      { headerText: "", rowText: "HitLine" },
      { headerText: "Name", rowText: "Name" },
      { headerText: "Address", rowText: "DisplayAddress" },
      { headerText: "Telephone", rowText: "Telephone" },
      { headerText: "", rowText: "" }
    ],
    pageSize: 20
  });

  this.populatePeople = function(items) {
    self.people.removeAll();
    jQuery.each(items, function(index, element) {
      var displayAddress = "";
      var streetAddress = "";
      var postCode = "";
      var location = "";
      var phone = new Array();
      if (element.Addresses != null && element.Addresses.length > 0) {
        displayAddress = element.Addresses[0].Street + " <br/> " + element.Addresses[0].Code + " " + element.Addresses[0].Locality;
        streetAddress = element.Addresses[0].Street;
        postCode = element.Addresses[0].Code;
        location = element.Addresses[0].Locality;
      }

      if (element.Phones != null && element.Phones.length > 0) {
        phone[0] = element.Phones[0].PhoneNumber;
        if (element.Phones.length > 1)
          phone[1] = element.Phones[1].PhoneNumber;
      }

      self.people.push({
        HitLine: element.HitLine,
        Name: element.DisplayName,
        FirstName: element.FirstName,
        LastName: element.LastName,
        StreetAddress: streetAddress,
        PostCode: postCode,
        DisplayAddress: displayAddress,
        Telephone: phone,
        Location: location,
        OrgNr: element.OrgNo,
        SelectedPhone: ko.observable()
      });
    });
  };

  this.selectResult = function (result) {
    // take result
  	// set values in the registration viewmodel
      PubSub.publish('EniroResultSelected', result);
      jQuery("#eniro-lightbox").modal('hide');
      ko.cleanNode(document.getElementById("ResultLightbox"));

  };

  this.applyResultWindowBindings = function (results) {
    self.populatePeople(JSON.parse(results));
    ko.applyBindings(self, document.getElementById("ResultLightbox"));
  };


  this.loadResultView = function (results, isVippsLogin) {
  	if (jQuery('#EniroResultLightBox').length == 0) {
          var container = jQuery('<div id="EniroResultLightBox"></div>');

          jQuery('body').append(container);

          var containerToLoad = "/Webpages/Kunde/EniroResultView.aspx";
          if (isVippsLogin)
              containerToLoad = "/Webpages/Kunde/VippsLoginResultView.aspx";

          container.load(containerToLoad, function () {
			  if (container.length < 1)
				  return false;

		      jQuery("#eniro-lightbox").modal({
		          show: true
		  });
			  jQuery('#UpdateIcon').hide();
			  self.applyResultWindowBindings(results);
			  jQuery("#eniro-lightbox").on('hidden.bs.modal', function () {
			      ko.cleanNode(document.getElementById("ResultLightbox"));
			  });
			  return true;
		  });
  	} else {
  	    jQuery("#eniro-lightbox").modal({
  	        show: true
  	    });
  		jQuery('#UpdateIcon').hide();
  		self.applyResultWindowBindings(results);
  		jQuery("#eniro-lightbox").on('hidden.bs.modal', function () {
  		    ko.cleanNode(document.getElementById("ResultLightbox"));
  		});
  	}
      
  };
 
  this.showResults = function (results, isVippsLogin) {
    self.loadResultView(results, isVippsLogin);
  };

  this.sendQueryAsync = function (id) {

    if (jQuery(id + ' #addressSearch').val()) {
      var data = {
          text: jQuery(id + ' #addressSearch').val(),
          commonname: jQuery('#eniroCommonName').text()
      };

      jQuery('#UpdateIcon').show();
      self.service.GetAction("SendQueryAsync", data).done(function(result) {
        if (result) {
          self.showResults(result);
        };
      });
    } else if (jQuery(id + ' #addressSearchCompany').val()) {
      var data1 = {
          text: jQuery(id + ' #addressSearchCompany').val(),
          commonname: jQuery('#eniroCommonName').text()
      };

      jQuery('#UpdateIcon').show();
      self.service.GetAction("SendQueryAsync", data1).done(function (result) {
        if (result) {
          self.showResults(result);
        };
      });
    };
  };

  this.triggerQueryByEnter = function (id, vm, event) {
    if (event.keyCode == 13) {
    	self.sendQueryAsync(id);
	    return false;
    }
	  return true;
  };
};
